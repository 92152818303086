@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #10141b; */
  font-size: 18px;
  line-height: 1.8;
  font-weight: 400;
  padding: 0;
  box-shadow: none !important; 
  /* overflow: hidden; */
  scroll-snap-type: y mandatory; 
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.child {      
  scroll-snap-align: center;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

code {
  font-family:'Montserrat', sans-serif;
}

@layer base {
  body {
    background-color: #10141b; 
  }
  :root {
    --color-primary: #10141b;
    --color-secondary: #1b212c; 
    --color-tertiary: #151c28;
    --color-primaryText: #ffbf00;
    --color-secondaryText: #cfd6e3;
    --color-tertiaryText: #10141b; 
    --color-card1: #ffdc73;
    --color-card2: #ffbf00;
    --color-navText: #cfd6e3;
    --color-timelineText: #151c28  
  }

  /* @media (prefers-color-scheme: dark) {
    body {
      background-color: #cfd6e3; 
    }
    :root {
      --color-primary: #c7c7c7;
      --color-secondary: rgb(174, 183, 201); 
      --color-tertiary: #151c28;
      --color-primaryText: #ff7300;
      --color-secondaryText: #10141b;
      --color-tertiaryText: #10141b; 
      --color-card1: #ffdc73;
      --color-card2: #ffbf00;
    }
  } */

  :root[data-theme="light"] {
    /* body {
      background-color: #cfd6e3; 
    } */
      --color-primary: #c7c7c7;
      --color-secondary: rgb(174, 183, 201); 
      --color-tertiary: #151c28;
      --color-primaryText: #ff7300;
      --color-secondaryText: #10141b;
      --color-tertiaryText: #10141b; 
      --color-card1: #ffdc73;
      --color-card2: #ffbf00;

  }
}

.scrollable { 
  overflow-x: auto;
  overflow-y: hidden;
}

 .testimonial {
  max-width: 1000px;
  width: 1000px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(456px, 1fr));
  gap: 4rem
 }


/* Animation for scrolling */
@keyframes scroll {
  0% { transform: translateY(0); }
  100% { transform: translateY(-100%); }
}
  
 
.portfolio-container:hover > .portfolio-content {
  transition: transform .3s ease;
  transform: translateX(0%);
}


.portfolio-content {
  transform: translateX(-100%);
  overflow: hidden;
}

.choose-now:hover > div {
  transition: transform .4s ease;
  transform: translateX(-90%);
  overflow: hidden;
}

.hover-images:hover { 
  transform: scale(1.1);
  transition: transform .3s ease-in-out;
  transition-duration: 500ms;
}

.blog-message {
  @apply lg:opacity-0 p-2
}
.blogpost:hover > .blog-message {  
  transition: opacity .3s ease-in-out;
  opacity: 1;
  transition-duration: 500ms;
}

.blogpost {
  @apply text-secondaryText
}
 


.progress {
  transition: width .7s;
}

.card-container {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops)); 
  background-color: #FDE68A; 
}

.home {
  background-image: url('https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
  background-repeat: no-repeat;
    background-size: cover;
    background-position: center center; 
    
}

.pulse {
  animation: pulse 2s infinite;
}

 
.timeline:hover {  
  cursor: pointer;
  .circle {
    transition: transform .3s ease-in-out; 
    transition-duration: 500ms;
    background-color: #ffbf00;
  
  }
}

.home::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height:80%;
  background-color: #1d3152;
  z-index: 1;
  opacity: .6;
}

@media (max-width: 1250px) {
  .home::after{
    height:82%;
  }

  .testimonial {
    max-width: 900px;
    width: 80px;
  }
  
}

@media (min-width: 1000px) {
 .portfolio {
  max-width: 1000px;
  width: 1000px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(456px, 1fr));
  gap: 3rem
 }
}

.round-dot {
  animation: move 2s linear infinite;
  transition-duration: 500ms;
}

@keyframes move {
  0%, 100% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(40px);  
  }
}

div.navbar > div:not('.logo') {
  opacity: 1;
}


.cacobasic-carousel-image-slider .swiper-scrollbar {
  position: relative;
  z-index: 50;
  height: 3px;
  width: 100%;
  max-width: 600px;
  margin-top: 35px;
  margin-bottom: 20px;
  right: auto;
  left: -10px;
  background-color: #303C51;
  border-radius: 0;
  cursor: pointer;
}
.swiper-scrollbar {
  border-radius: 10px;
  position: relative; 
  background: rgba(0,0,0,.1);
}
 
.cacobasic-carousel-image-slider .swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #cfd6e3;
  border-left: 10px solid #1A212C;
  border-right: 10px solid #1A212C;
  left: 0;
  top: -8px;
  height: 18px;
}
.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0,0,0,.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.cacobasic-carousel-image-slider .swiper-scrollbar {
  position: relative;
  z-index: 50;
  height: 3px 
px
;
  width: 100%;
  max-width: 600px;
  margin-top: 35px;
  margin-bottom: 20px;
  right: auto;
  left: -10px;
  background-color: #303C51;
  border-radius: 0;
  cursor: pointer;
}


.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .swiper-slide * {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
} */





.image-slider .owl-item {
  width: 100%;
}

.image-slider .owl-dots {
  text-align: left;
}

.image-slider .owl-dots .owl-dot span {
  width: 30px;
  height: 10px;
  border-radius: 0;
  margin-left: 0;
  margin-right: 15px;
  background-color: #232E41;
}

.image-slider .owl-dots .owl-dot.active span {
  background-color: #688CE8;
}

.swiper-slide {
  touch-action: none;
}

.carousel-image-slider .swiper-container {
  width: 80vw;
  margin-left: 0
}

@media screen and (max-width:1500px) {
  .carousel-image-slider .swiper-container {
      width: 95vw
  }
}

.carousel-image-slider .swiper-slide {
  width: auto;
  overflow: hidden
}

.carousel-image-slider {
  margin-top: 60px;
}

.carousel-image-slider .swiper-slide-testimonial-text {
  width: 320px;
  max-width: 90%;
  margin-right: 240px;
  margin-bottom: 60px
}

.carousel-image-slider img.testimonial-slide-img {
  width: 60px;
  margin-bottom: 30px;
  border-radius: 90%;
}

.carousel-image-slider .swiper-slide-name {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
  line-height: 120%
}

.carousel-image-slider .swiper-slide-position {
  font-size: 12px;
  margin: 0
}

@media screen and (min-width: 767px) {

  .carousel-image-slider .swiper-slide:after {
      content: "";
      position: absolute;
      width: 3px;
      height: 100%;
      background-color: #303c51;
      top: 0;
      right: 110px;
  }

  .carousel-image-slider .swiper-wrapper > div:last-of-type:after {
      display: none;
  }

}

.carousel-image-slider .swiper-wrapper>div:last-of-type {
  margin-right: 20% !important
}

.carousel-image-slider .swiper-wrapper>div:last-of-type:before {
  display: none
}

.carousel-image-slider .swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background-color: #cfd6e3;
  border-left: 10px solid #1a212c;
  border-right: 10px solid #1a212c;
  left: 0;
  top: -8px;
  height: 18px;
  border-radius: 0
}

.carousel-image-slider .swiper-scrollbar {
  position: relative;
  z-index: 50;
  height: 3px;
  width: 100%;
  max-width: 600px;
  margin-top: 80px;
  margin-bottom: 20px;
  right: auto;
  left: 0;
  background-color: #303c51;
  border-radius: 0;
  cursor: pointer
}

@layer components {
  .content-container {
    @apply flex flex-col pl-3 pr-3 pt-20  lg:justify-center lg:items-center gap-14 z-10
  }
}

/* .blogpost > ul > li {
  list-style-type: circle;
  @apply font-[500] tracking-wide leading-7
} */
.bullet {
  list-style-type:circle;
  @apply text-[16px] font-[600]
}

.number-bullet {
  list-style-type:decimal;
  @apply text-[16px] font-[600]
}
 
.scale-text:hover > p {
  animation: mymove 2s;
}

@keyframes mymove {
  50% {font-size: 30px;}
}

/*Start Animations*/
@-webkit-keyframes animatetop {
	from {
		top: -300px;
		opacity: 0;
	}
	to {
		top: 0;
		opacity: 1;
	}
}
@keyframes animatetop {
	from {
		top: -300px;
		opacity: 0;
	}
	to {
		top: 0;
		opacity: 1;
	}
}
@-webkit-keyframes zoomIn {
	0% {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}
	50% {
		opacity: 1;
	}
}
@keyframes zoomIn {
	0% {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}
	50% {
		opacity: 1;
	}
}
/*End Animations*/
/*
-- Start BackGround Animation 
*/
.area { 
  pointer-events: none; 
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 10;
}

.circles {
  opacity: 60%;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 96%;
	overflow: hidden;
}

.circles li {
	position: absolute;
	display: block;
	list-style: none;
	width: 20px;
	height: 20px;
	background: rgba(255, 255, 255, 0.05);
	animation: animate 15s linear infinite;
	bottom: -150px;
}

.circles li:nth-child(1) {
	left: 25%;
	width: 80px;
	height: 80px;
	animation-delay: 0s;
}

.circles li:nth-child(2) {
	left: 10%;
	width: 20px;
	height: 20px;
	animation-delay: 2s;
	animation-duration: 12s;
}

.circles li:nth-child(3) {
	left: 70%;
	width: 20px;
	height: 20px;
	animation-delay: 4s;
}

.circles li:nth-child(4) {
	left: 40%;
	width: 60px;
	height: 60px;
	animation-delay: 0s;
	animation-duration: 18s;
}

.circles li:nth-child(5) {
	left: 65%;
	width: 20px;
	height: 20px;
	animation-delay: 0s;
}

.circles li:nth-child(6) {
	left: 75%;
	width: 110px;
	height: 110px;
	animation-delay: 3s;
}

.circles li:nth-child(7) {
	left: 35%;
	width: 150px;
	height: 150px;
	animation-delay: 7s;
}

.circles li:nth-child(8) {
	left: 50%;
	width: 25px;
	height: 25px;
	animation-delay: 15s;
	animation-duration: 45s;
}

.circles li:nth-child(9) {
	left: 20%;
	width: 15px;
	height: 15px;
	animation-delay: 2s;
	animation-duration: 35s;
}

.circles li:nth-child(10) {
	left: 85%;
	width: 150px;
	height: 150px;
	animation-delay: 0s;
	animation-duration: 11s;
}

@keyframes animate {
	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 1;
		border-radius: 100%;
	}

	100% {
		transform: translateY(-1000px) rotate(720deg);
		opacity: 0;
		border-radius: 100%;
	}
}
/*
-- End BackGround Animation 
*/

.custom-shape-divider-bottom-1709398326 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1709398326 svg {
  position: relative;
  display: block;
  width: calc(132% + 1.3px);
  height: 192px;
}

.custom-shape-divider-bottom-1709398326 .shape-fill {
  fill: #929fb4;
}

.box-animate {    
  border: 3px solid #0000; 
  animation: 8s rotate linear infinite;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.blogpost > img:hover > p {
  bottom: 10;
}

.blog-scroll { 
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}
.blog-scroll::-webkit-scrollbar {
   width: 3px;
   height: 5px;
}

.blog-scroll::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgba(15, 11, 11, 0.3); 
   background-color: #10141b;
   width: 3px;
   border-radius: 10px;
}

.blog-scroll::-webkit-scrollbar-thumb {
   border-radius: 10px;
   -webkit-box-shadow: inset 0 0 6px rgba(20, 17, 17, 0.5); 
}


.snap-container {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.biraireality {
  background: url("https://plus.unsplash.com/premium_photo-1673795751644-e42b58452dc0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  object-fit: fill; 
}